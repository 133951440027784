@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

::selection {
  color: #ffffff !important;
  background: #96bf00;
}

.bg-gradient-custom {
  background: radial-gradient(circle at 50% 215%, #69bf00 0%, #000000 72%);
}

.text-gradient-custom {
  background: linear-gradient(90deg, #bee545 20%, #789801 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bg-news-card {
  background: linear-gradient(128deg, #000 0.27%, #333 99.73%);
}

.bg-radial {
  border-radius: 483px;
  background: radial-gradient(
    43.24% 43.24% at 50% 50%,
    rgba(190, 229, 69, 0.7) 0%,
    rgba(0, 0, 0, 0) 100%
  );
}

.bg-gradient-card {
  background: linear-gradient(110deg, #0e0e0e 24.95%, #1e1e1e 62.06%);
}

.bg-gradient-container {
  border-radius: 8px;
  border: 6px solid #707070;
  background: linear-gradient(128deg, #000 0.27%, #333 99.73%);
  box-shadow: 0px 4px 8px 0px #000;
}

.bg-footer {
  background: radial-gradient(50% 50% at 50% 50%, #96bf00 0%, #000 100%);
}

.bg-statements-svg {
  border-radius: 8px;
  background: linear-gradient(126deg, #bee545 12.34%, #96bf00 105.91%);
  box-shadow: 0px 4px 4px 0px #000;
}

.border-gallery {
  border-radius: 8px;
  border: 6px solid rgba(116, 109, 109, 0.5);
  background: linear-gradient(128deg, #000 0.27%, transparent 99.73%);
  box-shadow: 0px 4px 8px 0px #000;
}

.radial-gallery {
  border-radius: 509px;
  background: radial-gradient(
    43.24% 43.24% at 50% 50%,
    rgba(190, 229, 69, 0.7) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  width: 200px;
  height: 200px;
  flex-shrink: 0;
}

.swiper-pagination > .swiper-pagination-bullet {
  opacity: 1;
  background-color: white !important;
}
.swiper-pagination > .swiper-pagination-bullet-active {
  background-color: #96bf00 !important;
}

.swiper-pagination {
  width: fit-content !important;
  left: 75px !important;
}

@media screen and (max-width: 1536px) {
  .swiper-pagination {
    width: fit-content !important;
    left: 50px !important;
  }
}

@media screen and (max-width: 768px) {
  .swiper-pagination {
    width: fit-content !important;
    left: 20px !important;
  }
}

.button-gradient {
  background-image: linear-gradient(91deg, #96bf00 0.52%, #789801 128.21%);
}

.button-gradient:hover {
  background-image: linear-gradient(110deg, #0e0e0e 24.95%, #1e1e1e 62.06%);
  transition-duration: 300ms;
}

.bg-contact {
  border-radius: 8px;
  border: 6px solid #707070;
  background: linear-gradient(
    128deg,
    rgba(0, 0, 0, 0.5) 0.27%,
    rgba(51, 51, 51, 0.5) 99.73%
  );
  box-shadow: 0px 4px 8px 0px #000;
  backdrop-filter: blur(10px);
}

.text-shadow {
  text-shadow: 1px 1px 1px black, 0 0 1em black, 0 0 0.2em black;
}

::-webkit-input-placeholder {
  font-size: 14px !important;
}
:-moz-placeholder {
  font-size: 14px !important;
}
::-moz-placeholder {
  font-size: 14px !important;
}
:-ms-input-placeholder {
  font-size: 14px !important;
}

.css-13cymwt-control,
.css-t3ipsp-control {
  border-radius: 12px;
  color: white;
  background: linear-gradient(128deg, #000 0.27%, #333 99.73%);
  box-shadow: 0px 4px 8px 0px #000;
  height: 40px;
}

.css-1nmdiq5-menu {
  background-color: #1e1e1e !important;
  color: white !important;
}

#react-select-titleSearch-input {
  color: white !important;
}

#react-select-titleSearch-input:hover {
  color: #bee545 !important;
  background-color: transparent !important;
}
#react-select-titleSearch-placeholder {
  color: #f1f1f1;
}
.css-13cymwt-control {
  border-radius: 8px !important;
  border: 2px solid rgba(255, 255, 255, 0.31) !important;
  background: linear-gradient(91deg, #707070 0.49%, #1a1718 94.59%) !important;
}
